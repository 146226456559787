import React, { useEffect } from 'react'
// eslint-disable-next-line import/order
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { Space_Grotesk as SpaceGrotesk } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useApollo } from '@/lib/apolloClient'
import colors from '@/styles/_colors.module.scss'
import '@/styles/globals.scss'

const spaceGrotesk = SpaceGrotesk({ subsets: ['latin'] })

/* eslint-disable camelcase */
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    // eslint-disable-next-line camelcase
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    autocapture: {
      element_allowlist: [
        'a',
        'button',
        'form',
        'input',
        'label',
        'select',
        'textarea',
      ],
      dom_event_allowlist: ['click', 'change', 'submit'],
      url_allowlist: ['tryjourneyapp.com'],
    },
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug()
      }
    },
  })
}
/* eslint-enable camelcase */

export default function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps)
  const router = useRouter()

  // Send page view events to analytics
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture('$pageview')

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Head>
        <link
          color={colors.colorBg}
          href="/icons/safari-pinned-tab.svg"
          rel="mask-icon"
        />
        <meta
          content="black-translucent"
          name="apple-mobile-web-app-status-bar-style"
        />
        <meta
          content="width=device-width, initial-scale=1"
          name="viewport"
        />
        <meta
          content="yes"
          name="apple-mobile-web-app-capable"
        />
        <meta
          content={colors.colorBg}
          name="theme-color"
        />
      </Head>
      <main>
        <ApolloProvider client={apolloClient}>
          <style
            global
            jsx>{`
            html {
              font-family:
                ${spaceGrotesk.style.fontFamily},
                -apple-system,
                BlinkMacSystemFont,
                Segoe UI,
                Roboto,
                Oxygen,
                Ubuntu,
                Cantarell,
                Fira Sans,
                Droid Sans,
                Helvetica Neue,
                sans-serif;
            }
          `}</style>
          <PostHogProvider client={posthog}>
            <Component {...pageProps} />
          </PostHogProvider>
        </ApolloProvider>
      </main>
    </>
  )
}
