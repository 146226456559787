var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"zhwQdDffYvUNBc3ZXU_AC"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN =
  'https://44fad3043a0c4a7086349ab396ae9f5f@sentry.journey.tech/2'

Sentry.init({
  dsn: SENTRY_DSN || 'null',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      stickySession: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
})
